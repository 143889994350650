import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s1.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">In our work we have pride, quality is what we provide.</h3>
                                            <p>Every project we undertake reflects our commitment to excellence and attention to detail.
We believe in delivering solutions that stand the test of time and exceed expectations.
Our experienced team uses the latest techniques and technologies to ensure the highest standards.
With a focus on reliability, efficiency, and innovation, we consistently deliver outstanding results.
Trust us to bring your vision to life with the quality and precision you deserve</p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call For a Quote:</span>
                                                    <h4>(+966) 552623396</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"/contactus"} className="site-button-secondry btn-effect">Online Estimate Form</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;