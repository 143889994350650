import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';

var bnrimg = require('./../../images/banner/about-img1.webp');

const filters = [
    { label: "Civil Works ", filter: ".cat-1" },
    { label: "MEP Engineering ", filter: ".cat-2" },
    { label: "Bespoke Joinery ", filter: ".cat-3" },
    { label: "CAD and Revit Expertise", filter: ".cat-4" },
    { label: "Steel, Glass, and ACP Cladding", filter: ".cat-5" },
    
];

const projects = [
    {
        thumb: require('./../../images/projects/portrait/project-img1.jpg'),
        image: require('./../../images/projects/portrait/project-img1.jpg'),
        title: 'Bespoke Joinery',
        description: `Bespoke joinery creates custom woodwork, tailored to fit unique designs and spaces.`,
        filter: 'cat-1'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img2.jpg'),
        image: require('./../../images/projects/portrait/project-img2.jpg'),
        title: 'City Building',
        description: `We specialize in constructing durable, functional, and aesthetically pleasing buildings.`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img3.jpg'),
        image: require('./../../images/projects/portrait/project-img3.jpg'),
        title: 'Civil Works',
        description: `Civil works involve designing and constructing essential infrastructure like roads, bridges, and utilities.`,
        filter: 'cat-3'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img4.jpg'),
        image: require('./../../images/projects/portrait/project-img4.jpg'),
        title: 'Metropolitan Museum',
        description: `The Metropolitan Museum showcases a rich collection of art and culture from around the globe.`,
        filter: 'cat-4'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img5.jpg'),
        image: require('./../../images/projects/portrait/project-img5.jpg'),
        title: 'Construction',
        description: `We deliver construction solutions that combine innovation, precision, and durability. Building spaces that inspire and stand the test of time is our commitment.`,
        filter: 'cat-5'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img6.jpg'),
        image: require('./../../images/projects/portrait/project-img6.jpg'),
        title: 'Mep Engineering',
        description: `MEP Engineering ensures seamless mechanical, electrical, and plumbing systems for efficient, sustainable buildings.`,
        filter: 'cat-4'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img7.jpg'),
        image: require('./../../images/projects/portrait/project-img7.jpg'),
        title: 'Steel & Glass',
        description: `Steel and glass provide strong, modern designs that blend durability with elegance.`,
        filter: 'cat-3'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img8.jpg'),
        image: require('./../../images/projects/portrait/project-img8.jpg'),
        title: 'ACP Cladding',
        description: `ACP Cladding provides a durable, stylish finish with weather resistance for modern buildings.`,
        filter: 'cat-2'
    },
    {
        thumb: require('./../../images/projects/portrait/project-img9.jpg'),
        image: require('./../../images/projects/portrait/project-img9.jpg'),
        title: 'Cad & Revit',
        description: `CAD and Revit streamline design and modeling, enhancing precision and collaboration in projects.`,
        filter: 'cat-1'
    }
]

class ProjectGrid extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };
    
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Delivering innovative, precise solutions with quality and excellence in every project." pagename="Project Grid" bgimage={bnrimg}/>
                    
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            {/* PAGINATION START */}
                            <div className="filter-wrap p-b30 text-center">
                                <ul className="filter-navigation inline-navigation masonry-filter link-style  text-uppercase">
                                    <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                    {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                    ))} 
                                </ul>
                            </div>
                            {/* PAGINATION END */}
                            {/* GALLERY CONTENT START */}
                            <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                                {projects.map((item, index) => (
                                    <div key={index} className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}>
                                        <div className="image-effect-two hover-shadow">
                                            <img src={item.thumb} alt="" />
                                            <div className="figcaption">
                                                <h4 className="mt-tilte">{item.title}</h4>
                                                <p>{item.description}</p>
                                                <NavLink to="/services" className="read-more site-button btn-effect">Read More</NavLink>
                                                <a className="mfp-link" href={item.image}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            {/* GALLERY CONTENT END */}
                            <div className="m-t50 text-center">
                                <NavLink to={"/services"} className="site-button btn-effect">Load More</NavLink>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                <Footer />
            </>
        );
    };
};

export default ProjectGrid;