import React from 'react';
import { NavLink } from 'react-router-dom';

class Faq extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Some</span> FAQ</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="section-content p-b30">
                                        <div className="mt-accordion acc-bg-gray" id="accordion5">
                                            <div className="panel mt-panel">
                                                <div className="acod-head acc-actives">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                        Should I choose rates or instant payment?
                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse in">
                                                    <div className="acod-content p-tb15"><p>It depends on your preference and convenience. Opt for rates if you prefer to review and compare options before finalizing. Instant payment is ideal for quick, hassle-free transactions. Choose what best suits your needs and budget!</p></div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                        Can I see a live preview before deciding?

                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">Absolutely! We offer live previews to give you a clear idea of the final result. Experience the design, functionality, or layout firsthand before making your decision. Join us to explore and visualize your project in real time!</div>
                                                </div>
                                            </div>
                                            <div className="panel mt-panel">
                                                <div className="acod-head">
                                                    <h6 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                        How do I choose the correct service?
                          <span className="indicator"><i className="fa" /></span>
                                                        </a>
                                                    </h6>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-tb15">Start by identifying your specific needs and goals. Review the details of each service we offer to see which aligns best with your requirements. If you're unsure, our team is here to guide you and recommend the best option for your project.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-left">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Solution</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-gray center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-sketch" /></span>
                                                </div>
                                                <div className="icon-content text-black">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">Civil Works</h4>
                                                    <p>Civil works involve the construction and maintenance of essential infrastructure like roads, bridges, and buildings.</p>
                                                    <NavLink to="/about" className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                    <span className="icon-cell text-primary"><i className="flaticon-ui" /></span>
                                                </div>
                                                <div className="icon-content text-white">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">MEP Engineering 
                                                    </h4>
                                                    <p>MEP engineering designs mechanical, electrical, and plumbing systems for efficient and functional buildings and constructions.</p>
                                                    <NavLink to="/about" className="site-button-link yellow" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ */}
                </div>
            </>
        );
    }
};

export default Faq;