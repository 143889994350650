import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-6.png');

class Specialization3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0">We are all constructed out of our self dialogue.</h3>
                                <p>
                                Our internal dialogue shapes our reality, influencing our mindset, actions, and outcomes. Positive self-talk empowers growth and success, while negative thoughts limit our potential. By changing the narrative within, we can redefine our lives and become stronger, more resilient versions of ourselves.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/spec-1.jpg')}  alt=""/>
                                        <div className="figcaption">
                                            <h4>Construction</h4>
                                            <p>Engineering your dreams with us.</p>
                                            <NavLink to="/project-grid"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/services/spec2.jpg')} alt="" />
                                        <div className="figcaption">
                                            <h4>Architecture</h4>
                                            <p>Life is Architecture.</p>
                                            <NavLink to="/project-grid"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-b30">
                                    <div className="image-effect-one hover-shadow">
                                        <img src={require('../../images/rmzimg-1.webp')} alt=""/>
                                        <div className="figcaption bg-dark">
                                            <h4>Renovation</h4>
                                            <p>Dazzling Design</p>
                                            <NavLink to="/project-grid"><i className="link-plus bg-primary" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization3;